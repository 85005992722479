<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="유해화학자재 목록"
      tableId="grid"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :merge="grid.merge"
      :selection="popupParam.type"
      :usePaging="false"
      rowKey="psiHazardMaterialId"
      @linkClick="linkClick"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
          <c-btn v-if="editable" label="선택" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'hsl-manage-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'multiple',
          plantCd: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        merge: [
          { index: 0, colName: 'materialName' },
          { index: 1, colName: 'materialName' },
          { index: 5, colName: 'materialName' },
          { index: 6, colName: 'materialName' },
          { index: 7, colName: 'materialName' },
          { index: 8, colName: 'materialName' },
          { index: 9, colName: 'materialName' },
          { index: 10, colName: 'materialName' },
          { index: 11, colName: 'materialName' },
          { index: 12, colName: 'materialName' },
          { index: 13, colName: 'materialName' },
          { index: 14, colName: 'materialName' },
          { index: 15, colName: 'materialName' },
          { index: 16, colName: 'materialName' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장명',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'materialName',
            field: 'materialName',
            label: '화학자재명',
            align: 'left',
            style: 'width:200px',
            type: 'link',
            sortable: true,
          },
          {
            name: 'chemNmEn',
            field: 'chemNmEn',
            label: '화학물질명',
            align: 'left',
            style: 'width:200px',
            type: 'link',
            sortable: true,
          },
          {
            name: 'casNo',
            field: 'casNo',
            label: 'CAS No.',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'molecularFormula',
            field: 'molecularFormula',
            label: '분자식',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            label: '폭발한계(%)',
            align: 'center',
            child: [
              {
                name: 'explUpLimit',
                field: 'explUpLimit',
                label: '상한',
                align: 'center',
                style: 'width:70px',
                sortable: false
              },
              {
                name: 'explLowLimit',
                field: 'explLowLimit',
                label: '하한',
                align: 'center',
                style: 'width:70px',
                sortable: false
              },
            ]
          },
          {
            name: 'exposureLimit',
            field: 'exposureLimit',
            label: '노출기준',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'toxicValue',
            field: 'toxicValue',
            label: '독성치',
            align: 'center',
            style: 'width:300px',
            sortable: true,
          },
          {
            name: 'flashingPoint',
            field: 'flashingPoint',
            label: '인화점',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'ignitionPoint',
            field: 'ignitionPoint',
            label: '발화점',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'vaporPressure',
            field: 'vaporPressure',
            label: '증기압',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'corrosivenessFlag',
            field: 'corrosivenessFlag',
            label: '부식성유무',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'extraReaction',
            field: 'extraReaction',
            label: '이상반응유무',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'dailyUsage',
            field: 'dailyUsage',
            label: '일일취급량',
            align: 'center',
            style: 'width:100px',
            type: 'cost',
            sortable: true,
          },
          {
            name: 'stockage',
            field: 'stockage',
            label: '저장량',
            align: 'center',
            style: 'width:100px',
            type: 'cost',
            sortable: true,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '비고',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
        ],
        data: [],
        height: ''
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 100);
      if (height < 200) {
        height = 350;
      }
      this.grid.height = height + 'px'
    }
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.psi.hsl.hazardmaterials.url;
      if (this.popupParam.plantCd) {
        this.searchParam.plantCd = this.popupParam.plantCd;
      }
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row, col) {
      this.openChemMaterialPop(row, col.name);
    },
    openChemMaterialPop(row, _name) {
      let tabname = '';
      if (_name == 'materialName') {
        tabname = 'chemPsm';
      } else if (_name == 'chemNmKr') {
        tabname = 'chemMsds';
      }
      this.popupOptions.title = '화학자재 상세';
      this.popupOptions.param = {
        mdmChemMaterialId: row ? row.mdmChemMaterialId : '',
        plantCd: row ? row.plantCd : '',
        tabName: tabname,
      };
      this.popupOptions.target = () => import(`${'@/pages/mdm/mam/chemDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
